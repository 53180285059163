import React from "react";
import "./App.scss"
import Nav from "../Nav/Nav";
import PageHome from "../PageHome/PageHome";


import ReactGA from 'react-ga';
const TRACKING_ID = "UA-121432831-1"; // YOUR_OWN_TRACKING_ID
ReactGA.initialize(TRACKING_ID);
ReactGA.pageview(window.location.pathname + window.location.search);



const PODO_URL = process.env.REACT_APP_PODO_LINK
const CDD_URL = process.env.REACT_APP_CDD_LINK


let e404 = false;
// console.log("hash", window.location.hash)
if (window.location.hash.startsWith("#/zakazky") || window.location.hash.startsWith("#/zakazka")) {
  window.location.href = `${PODO_URL}/katalog`;
} else
  if (window.location.hash.startsWith("#/registrace"))  {
  window.location.href = `${CDD_URL}/#/registrace`;
} else
  if (window.location.hash.startsWith("#/cdd"))  {
    window.location.href = `${CDD_URL}`;
  } else
  if (window.location.hash.startsWith("#/faq"))  {
    window.location.href = `${CDD_URL}/#/faq`;
  } else
  if (window.location.hash.startsWith("#/mobilni-aplikace"))  {
    window.location.href = `${CDD_URL}/#/mobilni-aplikace`;
  } else
  if (window.location.hash.startsWith("#/ieny"))  {
    window.location.href = `${CDD_URL}/#/ieny`;
  } else
  if (window.location.pathname === "/404-not-found") {
  e404 = true
} else if (window.location.pathname !== "" && window.location.pathname !== "/") {
  window.location.href = "/404-not-found";
}
// else if (window.location.hash) {
//   window.location.hash = ''
// }
else if (window.location.hash.length > 2) {
  window.location.href = "/404-not-found";
}





function App() {

  return (
    <div className="App">

      <Nav/>
      {e404 ?

        <div className="text-center py-5">
          Stránka nenalezena
        </div>
        :
        <PageHome/>
      }
    </div>
  );
}

export default App;
