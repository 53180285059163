import React from "react";
import "./Footer.scss"

import logoTender from "./imgs/TenderArena-logo.png"
import logoEzak from "./imgs/E-ZAK-logo.png"
import logoPVU from "./imgs/PVU-logo.png"
import logoPodo from "./imgs/logo_podo.png"
import logoTenderM from "./imgs/TenderMarket-logo.png"
import logoGemin from "./imgs/Gemin-logo.png"

const Footer = () => {

  return (
    <footer className="py-4">

      <div className="container">

        <div className="logostrip">

          <a href="https://www.tenderarena.cz" target="_blank" rel="noreferrer noopener">
            <img src={logoTender} alt="Tenderarena logo"/>
          </a>

          <a href="http://www.ezak.cz/" target="_blank" rel="noreferrer noopener">
            <img src={logoEzak} alt="EZAK logo"/>
          </a>


          <a href="https://www.vhodne-uverejneni.cz/" target="_blank" rel="noreferrer noopener">
            <img src={logoPVU} alt="PVU logo"/>
          </a>

          <a href="https://podo.fen.cz/" target="_blank" rel="noreferrer noopener">
            <img src={logoPodo} alt="Podo logo"/>
          </a>


          <a href="https://www.tendermarket.cz/" target="_blank" rel="noreferrer noopener">
            <img src={logoTenderM} alt="Tendermarket logo"/>
          </a>

          <a href="https://www.gemin.cz/" target="_blank" rel="noreferrer noopener">
            <img src={logoGemin} alt="Gemin logo"/>
          </a>


        </div>

        <div className="separator"/>

        <div className="row my-4">
          <div className="col-md-4 col-lg-3 mb-2">
            <h4 className="heading">
              INFO O PROVOZOVATELI
            </h4>
            <div className="smallline afterline">
              Spojené nástroje elektronické s.r.o.
            </div>
            <div className="smallline afterline d-flex">
              <i className="fa-solid fa-map me-2 mt-1"></i>
              <div>
                Nad hradním vodojemem 1108/53,
                <br/>
                Střešovice, 162 00 Praha 6
              </div>
            </div>
            <div className="smallline afterline d-flex">
              <i className="fa-solid fa-envelope me-2 mt-1"></i>{" "}
              <a href="mailto:informace@fen.cz">informace@fen.cz</a>
            </div>
            <div className="smallline afterline d-flex">
              <i className="fa-solid fa-paper-plane me-2 mt-1"></i>{" "}
              ID datové schránky: 2dk7anf
            </div>
          </div>
          <div className="col-md-4 col-lg-5 offset-lg-1  mb-2">
            <h4 className="heading">
              HELP LINKA
            </h4>

            <div className="smallline mb-3">
              Máte nějaký dotaz? Kontaktujte nás
            </div>

            <div className="row mb-3">
              <div className="col-1">
                <i style={{ fontSize: 30 }} className="fas fa-phone-alt"></i>
              </div>
              <div className="col-10">

                <a href="tel:+420515917947">
                  +420 515 917 947
                  {" "}
                  <small>
                    (Registrace a Centrální databáze dodavatelů)
                  </small>
                </a>
                <br/>
                <a href="tel:+420538702710">
                  +420 538 702 710
                </a>
                {" "}
                <small>
                  (Portál dodavatele)
                </small>
                <br/>

                <a href="tel:+420538702719">
                  +420 538 702 719
                </a>
                {" "}
                <small>
                  (E-ZAK, Gemin)
                </small>
                <br/>

                <a href="tel:+420226258888">
                  +420 226 258 888
                </a>
                {" "}
                <small>
                  (Tender arena, TENDERMARKET)
                </small>

                <br/>

                <a href="tel:+420538702705">
                  +420 538 702 705
                </a>
                {" "}
                <small>
                  (PVU)
                </small>
                <br/>


              </div>

            </div>
            <div className="row">
              <div className="col-1">
                <i style={{ fontSize: 30 }} className="fa-regular fa-envelope"></i>
              </div>
              <div className="col-10">
                <a href="mailto:podpora@fen.cz">
                  podpora@fen.cz
                </a>

              </div>
            </div>


          </div>
          <div className="col-md-4 col-lg-3  mb-2">

            <h4 className="heading">
              SLEDUJTE NÁS!
            </h4>

            <a href="https://twitter.com/FEN2018a" target="_blank" rel="noreferrer noopener">
              <i style={{ fontSize: 50 }} className="fa-brands fa-x-twitter"/>
            </a>

          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
