import React from "react";
import "./PageHome.scss"
import Chart from "./Chart/Chart";
import Footer from "../Footer/Footer";

const CDD_LINK = process.env.REACT_APP_CDD_LINK
const PODO_LINK = process.env.REACT_APP_PODO_LINK


const PageHome = () => {

  return (

    <div className="wrap" style={{ background: "url(\"img/fix_podklad_FEN.png\") top center no-repeat" }}>

      <div className="container">


        {/*<div className="nadpis mb-4">*/}
        {/*  Služby pro dodavatele*/}
        {/*</div>*/}

        <div className="row mb-5">
          <div className="col-md-6">
            <div className="boxw">

              <img className="mb-5 mx-auto d-block" src="img/logo_podo.png" alt="Logo PODO"/>

              <h1 className="podonadpis mb-4">
                Všechny veřejné zakázky
                na jednom místě
              </h1>

              <div className=" mb-4 py-4">
                <div className="btnsblok">
                  <a href={PODO_LINK} className="btn btn-form w-100 ">
                    <i className="fa-solid fa-search"></i>
                    {" "}
                    NAJÍT ZAKÁZKU
                  </a>
                </div>
              </div>

              <div className="nadpismin mb-3">
                Nové zakázky za posledních 12 hodin
              </div>

              <Chart/>

            </div>
          </div>
          <div className="col-md-6">
            <div className="boxw">

              <img className="img-fluid mx-auto d-block my-2 " src="img/img_fen.png" alt="FEN"/>

              <div className="nadpismin mt-5 mb-4">
                Centrální databáze dodavatelů
              </div>
              <div className="btnsblok">
                <div className="boxtext text-center mb-5">
                  Centrální databáze dodavatelů je systém, který koncentruje všechny
                  zájemce o veřejné zakázky.
                </div>


                {/*<a className="btn btn-regis  w-100  mb-3" href={LOGIN_LINK}>*/}
                {/*  <i className="far fa-user-circle"/>*/}
                {/*  {" "}*/}
                {/*  Přihlásit*/}
                {/*</a>*/}
                <a className="btn btn-login w-100 " href={CDD_LINK}>
                  <i className="fa-solid fa-door-open"></i>
                  {" "}
                  Vstoupit
                </a>
              </div>
            </div>
          </div>
        </div>

        <div className="nadpis mb-4" style={{ maxWidth: "1000px", marginRight: "auto", marginLeft: "auto" }}>
          FEN – Projekt zlepšování veřejného zadávání v ČR
        </div>

        <div className="row mb-5">
          <div className="col-md-4">
            <div className="boxw">

              <div className="nadpismin">Spojili jsme síly</div>

              <i className="fa-solid fa-user-cog ikona"/>
              <div className="boxtext">
                Významní provozovatelé informačních systémů pro elektronizaci veřejných zakázek v Česku spojili síly a budují platformu FEN, která
                integruje to nejlepší z jednotlivých nástrojů a zároveň respektuje jednotlivé odlišnosti plynoucí z potřeb veřejných zadavatelů. Naším
                cílem je zjednodušit a zkvalitnit zadavatelské prostředí v ČR.
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="boxw">

              <div className="nadpismin">Řešení pro zadavatele</div>
              <i className="fa-solid fa-stamp ikona"/>
              <div className="boxtext">
                Respektujeme specifika jednotlivých zadavatelů a jejich individuální potřeby a zároveň přinášíme služby, které zvyšují efektivitu
                veřejného zadávání, jako například obsáhlá Centrální databáze dodavatelů nebo služby na publikaci zakázek, které zajistí, aby se jimi
                zabývalo maximum potenciálních dodavatelů.
              </div>

            </div>
          </div>
          <div className="col-md-4">
            <div className="boxw">

              <div className="nadpismin">Řešení pro dodavatele</div>
              <i className="fa-solid fa-envelope-open-text ikona"/>
              <div className="boxtext">
                Pracujeme na tom, aby se dodavatelům veřejných zakázek lépe žilo. Zjednodušujeme vyhledávání zakázek od všech zadavatelů v ČR,
                upozorňujeme na ty vhodné, poskytujeme analytické informace na základě historických dat. Díky jednotnému přihlášení skrz Centrální
                databázi dodavatelů je možné pracovat ve všech propojených systémech zadavatelů. Jednotná kvalifikace šetří rovněž velké množství
                administrativy.
              </div>
            </div>
          </div>
        </div>

        <div className="nadpis mb-4">
          Náš cíl
        </div>
        <div className=" mb-5">
          <div className="boxw">

            {/*<div className="nadpismin mb-4">*/}
            {/*  Co nejvíce zjednodušit dodavatelům přístup k zakázkám*/}
            {/*</div>*/}
            <div className="boxtext  text-center">
              Chceme, aby veřejné zadávání v ČR bylo efektivní, transparentní a maximálně jednoduché. Technologie má schopnost usnadnit život
              zadavatelům i dodavatelům. Na platformě FEN chceme vybudovat nejen technologické zázemí pro lepší zadávání, ale představit i možnosti,
              jak by zadávání mohlo fungovat v blízké budoucnosti.
            </div>
          </div>
        </div>


        <div className="py-4  mb-5">
          <img className="img-fluid mx-auto d-block" src="img/schema.png" alt="Schema"/>
        </div>

      </div>

      <Footer/>

    </div>
  );
}

export default PageHome;
