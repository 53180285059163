import React from "react";
import "./Nav.scss"


const Nav = () => {

  return (
    <header>

      <nav className="navbar navbar-expand-lg navbar-light bg-light">
        <div className="container">
          <a className="navbar-brand" href="/">
            <img src="img/logo_fen.png" alt="Logo FEN"/>
          </a>
          <div className="navbar-text ">
            <strong>
              Veřejné zakázky snadno a spolehlivě
            </strong>
          </div>
          {/*<button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"*/}
          {/*        aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">*/}
          {/*  <span className="navbar-toggler-icon"/>*/}
          {/*</button>*/}
          {/*<div className="collapse navbar-collapse" id="navbarSupportedContent">*/}
          {/*  <ul className="navbar-nav ms-auto mb-2 mb-lg-0">*/}
          {/*    <li className="nav-item">*/}
          {/*      <a className="nav-link rednav " href={PODO_LINK}>*/}
          {/*        <i className="fas fa-search"/>*/}
          {/*        {" "}*/}
          {/*        Hledat*/}
          {/*      </a>*/}
          {/*    </li>*/}
          {/*    <li className="nav-item ms-2 ">*/}
          {/*      <a className="nav-link" href={LOGIN_LINK}>*/}
          {/*        <i className="far fa-user-circle"/>*/}
          {/*        {" "}*/}
          {/*        Přihlášení*/}
          {/*      </a>*/}
          {/*    </li>*/}
          {/*    <li className="nav-item ms-2">*/}
          {/*      <a className="nav-link" href={REGISTER_LINK}>*/}
          {/*        <i className="fas fa-user-plus"/>*/}
          {/*        {" "}*/}
          {/*        Nová registrace*/}
          {/*      </a>*/}
          {/*    </li>*/}

          {/*    <li className="nav-item ms-4">*/}
          {/*      <a className="nav-link " href="#">*/}
          {/*        EN*/}
          {/*      </a>*/}
          {/*    </li>*/}
          {/*  </ul>*/}
          {/*</div>*/}
        </div>
      </nav>
    </header>
  );
}

export default Nav;