interface TooltipItems {
  datasetIndex: number;
  index: number;
  label: string;
  value: string;
  x: number;
  xLabel: string;
  y: number;
  yLabel: number;
}

//
// export interface IConfig {
//   title: {
//     padding: number;
//   };
//   datasets: {
//     borderColor: string;
//     borderCapStyle: string;
//     borderJoinStyle: string;
//     lineTension: number;
//     xAxisId: string;
//     yAxisId: string;
//     pointRadius: number;
//     pointHoverRadius: number;
//     fill: boolean;
//     showLine: boolean;
//     data: Array<number>;
//     backgroundColor: string | Array<string>;
//   };
//   options: {
//     legend: {
//       display: boolean;
//     };
//     scales: {
//       yAxes: Array<{
//         id: string;
//         display: boolean;
//         type: string;
//         gridLines: {
//           display: boolean;
//           drawOnChartArea?: boolean;
//         };
//         ticks?: {
//           stepSize?: number;
//           callback?: (value: string) => string;
//           maxTicksLimit?: number;
//           beginAtZero: boolean;
//         };
//       }>;
//       xAxes: Array<{
//         id: string;
//         display: boolean;
//         type?: string;
//         gridLines: {
//           display: boolean;
//           drawOnChartArea?: boolean;
//         };
//         ticks: {
//           callback: (value: string) => string;
//           maxTicksLimit?: number;
//         };
//       }>;
//     };
//     responsive: boolean;
//     tooltips: {
//       displayColors: boolean;
//       enabled: boolean;
//       mode: string;
//       callbacks: {
//         label: (tooltipItems: TooltipItems, data: string) => string;
//         title: (tooltipItems: Array<TooltipItems>, data: string) => string;
//       };
//     };
//     plugins: {
//       datalabels: {
//         color: string;
//         font: {
//           weight: string;
//           size: number;
//         };
//         display: boolean;
//         formatter: (value: string, context: { active: boolean }) => string;
//       };
//     };
//   };
// }

const config = {
  title: {
    padding: 30
  },
  datasets: {
    borderColor: "rgba(241, 196, 15, 1)",
    borderCapStyle: "round",
    borderJoinStyle: "round",
    lineTension: 0.4,
    xAxisId: "x-0",
    yAxisId: "y-0",
    pointRadius: 0,
    pointHoverRadius: 0,
    fill: true,
    showLine: true,
    // data: [],
    // backgroundColor: ["#c73b3b", "#c73b3b", "#c73b3b", "#c73b3b", "#c73b3b", "#c73b3b", "#c73b3b", "#c73b3b", "#e78a8a"]
  },
  options: {
    legend: {
      display: false
    },
    scales: {
      yAxes: [
        {
          id: "y-0",
          display: true,
          type: "linear",
          gridLines: {
            display: true,
            drawOnChartArea: false
          },
          ticks: {
            stepSize: 1,
            maxTicksLimit: 3,
            beginAtZero: true
          }
        }
      ],
      xAxes: [
        {
          id: "x-0",
          display: true,
          gridLines: {
            display: true,
            drawOnChartArea: false
          },
          ticks: {
            callback: function (value: string): string {
              return value.replace(". hodina", ":00");
            }
          }
        }
      ]
    },
    responsive: true,
    tooltips: {
      enabled: true,
      mode: "single",
      displayColors: false,
      callbacks: {
        title: function (tooltipItems: TooltipItems[]) {
          return `Hodina ${tooltipItems[0].label}`;
        },
        label: function (tooltipItems: TooltipItems) {
          if (parseInt(tooltipItems.value, 10) === 1) return `${tooltipItems.value} zakázka`;
          if (parseInt(tooltipItems.value, 10) > 1 && parseInt(tooltipItems.value, 10) < 5)
            return `${tooltipItems.value} zakázky`;

          return `${tooltipItems.value} zakázek`;
        }
      }
    },
    plugins: {
      datalabels: {
        color: "white",
        font: {
          weight: "bold",
          size: 13
        },
        display: true,
        formatter: function (value: any) {
          return parseInt(value, 10) >= 3 ? value : "";
        }
      }
    }
  }
};

export default config;
