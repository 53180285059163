import React, { useEffect, useState } from "react";
import config from "./Chart.config";
import { Bar } from "react-chartjs-2";
import "chartjs-plugin-datalabels";

const API_URL = process.env.REACT_APP_API_URL

const dataFake = {
  "frequency": {
    "11-12": 3,
    "10-11": 25,
    "9-10": 12,
    "8-9": 10,
    "7-8": 9,
    "6-7": 6,
    "5-6": 3,
    "4-5": 6,
    "3-4": 0,
    "2-3": 10,
    "1-2": 0,
    "0-1": 0
  }
}
const Chart = () => {


  const [res, setRes] = useState(null as any)
  const [loading, setLoading] = useState(false)
  const [err, setErr] = useState("")

  const data = !res ? [] : Object.values(res).slice(0, 12).reverse() as number[]
  const labels = !res ? [] : Object.keys(res).slice(0, 12).reverse();

  useEffect(() => {
    //TODO error
    setLoading(true)
    setErr("")

    fetch(`${API_URL}/contracts/stats`)
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("Chyba dat");
        }
      })
      .then(res => {
        setRes(res.data.frequency)
      })
      .catch(error => {
        // setErr("Chyba načení dat")
        setRes(dataFake.frequency)// TODO smazat
      })
      .finally(() => {
        setLoading(false)
      })

  }, [])

  const buildData = () => {
    const dataset = {
      ...config.datasets,
      data,
      backgroundColor: [
        ...Array(11).fill("#c73b3b"),
        "#e78a8a"
      ]
    }
    return { datasets: [dataset], labels };
  };

  if (loading) {
    return <div className="text-center">Nahrávám</div>
  }

  if (err) {
    return <div className="text-center text-danger">{err}</div>
  }

  return (
    <div style={{
      display: "flex",
      padding: "0 0 40px 0",
      justifyContent: "center",
      width: "100%",
    }}>
      <Bar data={buildData} height={128} options={config.options}/>
    </div>
  );

}

export default Chart;